import React, {Suspense, useEffect, useState, useRef} from 'react'
import {Helmet} from "react-helmet-async"
import Page from '../Page/Page'
import classes from './Order.module.css'
import Arrow from '../../assets/images/arrow_cat.svg'
import { priceWithSpace } from '../../utils/priceWithSpace'
import clsx from 'clsx'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import Placeholder from '../../assets/images/no_item.png'
import { getSalesPrice, getTotalDefaultPrice } from '../../utils/getTotalPrice'
import { CustomerService } from '../../api/CustomerService'
import {Loader} from "../../components/loader/Loader";
import {Await, useLoaderData} from "react-router-dom";
import {ErrorElement} from "../../components/errorElement/ErrorElement"
import {IDeliveryType} from "../../interfaces/DeliveryType"
import {IRetailPoint} from "../../interfaces/RetailPoint"
import {IOrder} from "../../interfaces/Order";
import {IAddress} from "../../interfaces/Address";
import {OrderService} from "../../api/OrderService";
import {SearchAddressService} from "../../api/SearchAddress";
import {ISearchAddressValue} from "../../interfaces/ISearchAddress"

type PromiseType = {
    deliveryTypes: Array<Promise<IDeliveryType>>
    retailPoints: Array<Promise<IRetailPoint>>
}

const Order = () => {

    const {deliveryTypes, retailPoints} = useLoaderData() as PromiseType

    const orderService = new OrderService()
    const searchAddress = new SearchAddressService()

    const cityBlockRef = useRef<HTMLDivElement>(null)
    const cityInputRef = useRef<HTMLDivElement>(null)
    const streetBlockRef = useRef<HTMLDivElement>(null)
    const streetInputRef = useRef<HTMLDivElement>(null)

    const { cart, promocode, promocodePrice } = useTypedSelector((state) => state.cartReducer)
    const { user } = useTypedSelector((state) => state.userReducer)
    //TODO кинуть в useReducer когда будет понятна структура
    const [bonuses, setBonuses] = useState(0)
    const [typedBonuses, setTypedBonuses] = useState("")
    const [userBalance, setUserBalance] = useState(0)
    const [name, setName] = useState(user?.firstName || '')
    const [lastName, setLastName] = useState(user?.lastName || '')
    const [middleName, setMiddleName] = useState('')
    const [phone, setPhone] = useState(user?.phone || '')
    const [email, setEmail] = useState(user?.email || '')
    const [deliveryType, setDeliveryType] = useState<string | null>(null)

    const [filteredRetailPoints, setFilteredRetailPoints] = useState<Array<IRetailPoint>>([])
    const [retailPoint, setRetailPoint] = useState<string>()

    const [city, setCity] = useState<ISearchAddressValue | null>(null)
    const [cityInput, setCityInput] = useState<string>("")
    const [cities, setCities] = useState<Array<ISearchAddressValue>>([])

    const [street, setStreet] = useState<ISearchAddressValue | null>(null)
    const [streetInput, setStreetInput] = useState<string>("")
    const [streets, setStreets] = useState<Array<ISearchAddressValue>>([])

    const [house, setHouse] = useState<string>("")
    const [apartment, setApartment] = useState<string>("")

    useEffect(() => {
        let isMount = true
        const getBalance = async() => {
            try {
                const api = new CustomerService()
                const balance = await api.getBalance()
                if(isMount) setUserBalance(balance)
            } catch (e) {
                console.log(e)
            }
        }
        getBalance().then()

        return () => {
            isMount = false
        }
    }, [])

    useEffect(() => {
        const filtered = filteredRetailPoints.filter((rp) => {
            if (city) {
                return rp.addressFull.city === city.data.city
            }
        })

        setFilteredRetailPoints(filtered)

        if (deliveryType === "639c14a2aba8fec3262010d3") {
            if (filtered[0])
                setRetailPoint(filtered[0]._id)
        }
    }, [deliveryType])

    useEffect(() => {
        let rps: Array<IRetailPoint> = []
        Promise.resolve(retailPoints).then((retailPoints) => {
            retailPoints.map((rp) => {
                Promise.resolve(rp).then((r) => {
                    rps.push(r)
                })
            })
        })
        setFilteredRetailPoints(rps)
    }, [retailPoints])

    useEffect(() => {
        if (cityInput.length > 2) {
            searchAddress.get(cityInput, false, undefined)
                .then((data) => {
                    setCities(data.suggestions)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [cityInput])

    useEffect(() => {
        if (city) {
            let rps: Array<IRetailPoint> = []
            Promise.resolve(retailPoints).then((retailPoints) => {
                retailPoints.map((rp) => {
                    Promise.resolve(rp).then((r) => {
                        rps.push(r)
                    })
                })
            })
            setFilteredRetailPoints(rps)
        }
    }, [city])

    useEffect(() => {
        if (streetInput.length > 2) {
            searchAddress.get(streetInput, true, city?.data.city)
                .then((data) => {
                    setStreets(data.suggestions)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [streetInput])

    const pay = (e: React.MouseEvent) => {
        e.preventDefault()
        const btn = e.currentTarget as HTMLButtonElement
        btn.disabled = true
        btn.classList.toggle("btnLoading")

        let address: IAddress
        if (deliveryType === "639c14a2aba8fec3262010d3") {
            address = {
                deliveryTypeId: deliveryType || "",
                retailPointId: retailPoint
            }
        } else {
            address = {
                deliveryTypeId: deliveryType || "",
                city: city?.value,
                street: street?.value,
                house,
                apartment,
                apartmentType: "кв"
            }
        }

        console.log(address)

        const payload: IOrder = {
            address,
            bonusOut: bonuses.toString(),
            check: false,
            comment: "test",
            promocode,
            sourceId: "639c1b3a1ebccb09c5beb660"
        }

        orderService.create(payload)
            .then((data) => {
                console.log(data)
                orderService.confirm()
                    .then((data) => {
                        console.log(data)
                        btn.classList.toggle("btnLoading")
                        window.open(data)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
            .catch((err) => {
                console.log(err)
                btn.disabled = false
                btn.classList.toggle("btnLoading")
            })
    }

    const onChangeCity = (e: React.MouseEvent) => {
        e.preventDefault()
        if (cityBlockRef && cityBlockRef.current) {
            cityBlockRef.current.classList.add("d-none")
        }

        if (cityInputRef && cityInputRef.current) {
            cityInputRef.current.classList.remove("d-none")
        }
    }

    const onPickCity = (e: React.MouseEvent, c: ISearchAddressValue) => {
        e.preventDefault()
        setCity(c)

        if (cityBlockRef && cityBlockRef.current) {
            cityBlockRef.current.classList.remove("d-none")
        }

        if (cityInputRef && cityInputRef.current) {
            cityInputRef.current.classList.add("d-none")
        }
    }

    const onChangeStreet = (e: React.MouseEvent) => {
        e.preventDefault()

        if (streetBlockRef && streetBlockRef.current) {
            streetBlockRef.current.classList.add("d-none")
        }

        if (streetInputRef && streetInputRef.current) {
            streetInputRef.current.classList.remove("d-none")
        }
    }

    const onPickStreet = (e: React.MouseEvent, c: ISearchAddressValue) => {
        e.preventDefault()
        setStreet(c)

        if (streetBlockRef && streetBlockRef.current) {
            streetBlockRef.current.classList.remove("d-none")
        }

        if (streetInputRef && streetInputRef.current) {
            streetInputRef.current.classList.add("d-none")
        }
    }

    const onSpentBonuses = (e: React.MouseEvent) => {
        e.preventDefault()
        if (Math.floor(userBalance/2) < parseFloat(typedBonuses)) {
            alert(`Максимальная сумма оплаты бонусами этого для заказа ${Math.floor(userBalance/2)}`)
            return
        }

        setBonuses(parseFloat(typedBonuses))
    }
    
    return (
        <Page hideMiniCart>
            <Helmet>
                <title>Офомление заказа</title>
                <link rel="canonical" href="https://parfumeratelier.ru/order" />
            </Helmet>
            <div className={classes.order}>
                <div className={classes.body}>
                    <div className={classes.step}>
                        <div className={classes.header}>
                            <span>1/3</span>
                            <span>адрес и доставка</span>
                            <img src={Arrow} alt='Свернуть/Раскрыть' />
                        </div>

                        <div className={classes.row}>
                            <p>населённый пункт</p>
                            <div ref={cityBlockRef} className={classes.place}>
                                {
                                    city ? (
                                        <>
                                            <span>{city.value}</span>
                                            <span>{city.data.region_with_type}</span>
                                        </>
                                    ) : (<span>Выберите город</span>)
                                }
                                <button className="btn--sup" onClick={onChangeCity}>изменить город</button>
                            </div>
                            <div className={clsx(classes.place, "d-none")} ref={cityInputRef}>
                                <div className="form__input">
                                    <label>Город</label>
                                    <input
                                        type="text"
                                        name="city"
                                        value={ cityInput }
                                        onChange={ ({ target: { value}}) => setCityInput(value) } />
                                </div>
                                <ul className={classes.suggest}>
                                    {
                                        cities.map((c, i) => <li key={`city_${i}`} onClick={(e) => onPickCity(e, c)}>{c.value}</li>)
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className={clsx(classes.row, {["d-none"]: !city})}>
                            <p>способ доставки</p>
                            <div className={classes.place}>
                                <Suspense fallback={<Loader />}>
                                    <Await
                                        resolve={deliveryTypes}
                                        errorElement={
                                            <ErrorElement msg='Не удалось загрузить способы доставки' />
                                        }>
                                        {(dts: Array<IDeliveryType>) => {
                                            let notice = ""
                                            const currentDt = dts.find((dt) => dt._id === deliveryType)
                                            if (currentDt) {
                                                notice = currentDt.slug
                                            } else {
                                                setDeliveryType(dts[0] && dts[0]._id)
                                            }
                                            return (
                                                <>
                                                    <div className="select-box">
                                                        <div className="select-box__current" tabIndex={1}>
                                                            {
                                                                dts.map((dt) => (
                                                                    <div key={dt._id} className="select-box__value">
                                                                        <input
                                                                            className="select-box__input"
                                                                            type="radio"
                                                                            id={dt._id}
                                                                            value={dt._id}
                                                                            onChange={() => setDeliveryType(dt._id)}
                                                                            name="deliveryType"
                                                                            checked={dt._id === deliveryType} />
                                                                        <p className="select-box__input-text">{dt.name}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                            <img className="select-box__icon"
                                                                 src={Arrow}
                                                                 alt="Arrow Icon" aria-hidden="true"/>
                                                        </div>
                                                        <ul className="select-box__list">
                                                            {
                                                                dts.map((dt) => (
                                                                    <li key={`li_${dt._id}`}>
                                                                        <label className="select-box__option" htmlFor={dt._id}
                                                                               aria-hidden="true">{dt.name}</label>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                    <p className={classes.deliveryNotice}>{notice}</p>
                                                </>
                                            )}
                                        }
                                    </Await>
                                </Suspense>
                            </div>
                        </div>

                        <div className={clsx(classes.row, {["d-none"]: !city})}>
                        {
                            deliveryType && deliveryType === "639c14a2aba8fec3262010d3" ? (
                                <>
                                    <p>точка самовывоза</p>
                                    <div className={classes.place}>
                                        <div className="select-box">
                                            <div className="select-box__current" tabIndex={1}>
                                                {
                                                    filteredRetailPoints.map((rp) => (
                                                        <div key={rp._id} className="select-box__value">
                                                            <input
                                                                className="select-box__input"
                                                                type="radio"
                                                                id={rp._id}
                                                                value={rp._id}
                                                                onChange={() => setRetailPoint(rp._id)}
                                                                name="retailPoint"
                                                                checked={rp._id === retailPoint} />
                                                            <p className="select-box__input-text">{rp.name}</p>
                                                        </div>
                                                    ))
                                                }
                                                <img className="select-box__icon"
                                                     src={Arrow}
                                                     alt="Arrow Icon" aria-hidden="true"/>
                                            </div>
                                            <ul className="select-box__list">
                                                {
                                                    filteredRetailPoints.map((rp) => (
                                                        <li key={`li_${rp._id}`}>
                                                            <label className="select-box__option" htmlFor={rp._id}
                                                                   aria-hidden="true">{rp.name}</label>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p>адрес доставки</p>
                                    <div className={classes.place} ref={streetBlockRef}>
                                        <span>{street?.value}, д. 54, кв. 104</span>
                                        <button className="btn--sup" onClick={onChangeStreet}>изменить адрес</button>
                                    </div>
                                    <div className={clsx(classes.place, "d-none")} ref={streetInputRef}>
                                        <div className="form__input">
                                            <label>Улица</label>
                                            <input
                                                type="text"
                                                name="street"
                                                value={ streetInput }
                                                onChange={ ({ target: { value}}) => setStreetInput(value) } />
                                        </div>
                                        <ul className={classes.suggest}>
                                            {
                                                streets.map((c, i) => <li key={`city_${i}`} onClick={(e) => onPickStreet(e, c)}>{c.value}</li>)
                                            }
                                        </ul>
                                    </div>
                                </>
                            )
                        }
                        </div>
                    </div>

                    <div className={classes.step}>
                        <div className={classes.header}>
                            <span>2/3</span>
                            <span>получатель</span>
                            <img src={Arrow} alt='Свернуть/Раскрыть' />
                        </div>

                        <div className={classes.row}>
                            <p>Ваши данные</p>
                            <div className={classes.place}>
                                <div className="form__input">
                                    <label>Имя</label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={ name }
                                        onChange={ ({ target: { value}}) => setName(value) } />
                                </div>
                                <div className="form__input">
                                    <label>Фамилия</label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={ lastName }
                                        onChange={ ({ target: { value}}) => setLastName(value) } />
                                </div>
                                <div className="form__input">
                                    <label>Отчество</label>
                                    <input
                                        type="text"
                                        name="middleName"
                                        value={ middleName }
                                        onChange={ ({ target: { value}}) => setMiddleName(value) } />
                                </div>
                            </div>
                        </div>

                        <div className={classes.row}>
                            <p>контакты</p>
                            <div className={classes.place}>
                                <div className="form__input">
                                    <label>Телефон</label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={ phone }
                                        onChange={ ({ target: { value}}) => setPhone(value) } />
                                </div>
                                <div className="form__input">
                                    <label>E-mail</label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={ email }
                                        onChange={ ({ target: { value}}) => setEmail(value) } />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.step}>
                        <div className={classes.header}>
                            <span>3/3</span>
                            <span>оплата</span>
                            <img src={Arrow} alt='Свернуть/Раскрыть' />
                        </div>

                        <div className={classes.row}>
                            <span />
                            <div className={classes.place}>
                                <div className='groupTag'>
                                    <input
                                        type='radio'
                                        className='checkbox'
                                        id='online'
                                        name='payment'
                                        value='online'
                                        defaultChecked
                                    />
                                    <label htmlFor='online'>
                                        Online оплата
                                    </label>
                                </div>
                                <div>
                                    <h3>У вас {userBalance-bonuses} бонусов</h3>
                                    <div className='promo-item-list__item-wrapper'>
                                        <div className="form__input">
                                            <label>Количество бонусов</label>
                                            <input
                                                type="text"
                                                name="bonuses"
                                                inputMode="numeric"
                                                value={ typedBonuses }
                                                onChange={ ({ target: { value}}) => setTypedBonuses(value) } />
                                            <small>Максимум 50% от суммы заказа</small>
                                        </div>
                                        <button className="btn--sup" onClick={onSpentBonuses}>Списать</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.step}>
                        <div className={classes.row}>
                            <span />
                            <button onClick={pay} className='btn'>
                                <span>Оплатить online</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className={classes.detail}>
                    <h2>ваш заказ</h2>
                    <div className={classes.detailProducts}>
                        {cart.map((c) => (
                            <img
                                key={c._id}
                                src={c.product.imgUrl[0] || Placeholder}
                                alt={c.product.name}
                            />
                        ))}
                    </div>
                    <div className={classes.attrs}>
                        <dl className={classes.tags}>
                            <div className={classes.tag}>
                                <dt className={classes.tagKey}>
                                    <span>сумма заказа</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>
                                        {priceWithSpace(
                                            getTotalDefaultPrice(cart)
                                        )}
                                    </span>
                                </dd>
                            </div>
                            <div className={clsx(classes.tag, classes.discount)}>
                                <dt className={classes.tagKey}>
                                    <span>скидка</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>
                                        {priceWithSpace(getSalesPrice(cart))}
                                    </span>
                                </dd>
                            </div>
                            {
                                promocode && promocodePrice > 0 ? (
                                    <div className={clsx(classes.tag, classes.discount)}>
                                        <dt className={classes.tagKey}>
                                            <span>Промокод {promocode}</span>
                                        </dt>
                                        <dd className={classes.tagValue}>
                                            <span>{-promocodePrice}</span>
                                        </dd>
                                    </div>
                                ) : null
                            }
                            <div className={clsx(classes.tag, classes.discount)}>
                                <dt className={classes.tagKey}>
                                    <span>бонусы</span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{priceWithSpace(-bonuses)}</span>
                                </dd>
                            </div>
                            <div className={clsx(classes.tagTotal)}>
                                <span>Итого</span>
                                <span>
                                    {priceWithSpace(
                                        getTotalDefaultPrice(cart) -
                                        getSalesPrice(cart) -
                                        bonuses - promocodePrice
                                    )}
                                    ₽
                                </span>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </Page>
    )
}

export default Order
