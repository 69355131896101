import React, { useEffect, useState } from 'react'
import classes from './itemsGrid.module.css'

import Item from '../item/item'
import { IItem } from '../../interfaces/Item'
import { ITag } from '../../interfaces/TagGroup'
import { prepareTags } from '../../utils/prepareTags'
import {getMinPrice} from "../../utils/getMinPrice";
import {returnTrue} from "react-number-format/types/utils";

type Props = {
    items: Array<IItem>
    filters: ITag[]
    filterPrice: number[]
}

const ItemsGrid: React.FC<Props> = ({ items, filters, filterPrice }) => {
    const [_items, setItems] = useState<IItem[]>(items)

    useEffect(() => {
        setItems(items)
    }, [items])

    // useEffect(() => {
    //     //Надо тестировать с больщим числом тэгов у товара и упростить по возможности
    //     setItems(
    //         items.filter((item) => {
    //             const [priceDefault, priceSale] = getMinPrice(item)
    //             if (
    //                 filterPrice[1] !== -1 &&
    //                 (priceDefault < filterPrice[0] ||
    //                     priceDefault > filterPrice[1])
    //             )
    //                 return false
    //             let isFound = true
    //             const { tags } = item
    //             const preparedTags = prepareTags(tags)
    //             const preparedFilters = prepareTags(filters)
    //             preparedFilters.forEach((tag) => {
    //                 const preparedTag = preparedTags.find(
    //                     (f) => f._id === tag._id
    //                 )
    //                 isFound = false
    //                 if (preparedTag) {
    //                     tag.tags.forEach((_tag) => {
    //                         preparedTag.tags.forEach((pt) => {
    //                             if (_tag === pt) isFound = true
    //                         })
    //                     })
    //                 }
    //             })
    //             return isFound
    //         })
    //     )
    // }, [filters, filterPrice])

    return (
        <div className={classes.items}>
            {_items.length > 0 ? (
                _items.map((i) => {
                    const [priceDefault, priceSale] = getMinPrice(i)
                    return (
                        <Item
                            key={i._id}
                            image={i.imgUrl[0]}
                            name={i.name}
                            price={priceDefault}
                            salesPrice={priceSale}
                            categories={i.categories}
                            slug={i.slug}
                            badges={i.badges}
                        />
                    )
                })
            ) : (
                <p>В данном разделе ничего</p>
            )}
        </div>
    )
}

export default ItemsGrid
