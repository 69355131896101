import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import classes from './header.module.css'
import Logo from '../../assets/images/logo.png'
import { NavLink, Link } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { CustomerService } from '../../api/CustomerService'
import Account from "../../assets/images/account.png"

interface IHeader {
    transparent: boolean
}

const Header: React.FC<IHeader> = ({ transparent }) => {
    const [offset, setOffset] = useState(0)
    const [prevOffset, setPrevOffset] = useState(0)
    const [showLinks, setShowLinks] = useState(true)
    const [hamIsActive, setHamIsActive] = useState(false)
    const [balance, setBalance] = useState(0)
    const { user } = useTypedSelector((state) => state.userReducer)

    useEffect(() => {
        let isMount = true
        const getBalance = async() => {
            try {
                const api = new CustomerService()
                const _balance = await api.getBalance()
                if(isMount) setBalance(_balance)
            } catch (e) {
                console.log(e)
            }
        }
        getBalance().then()
        const onScroll = () => {
            setOffset(window.scrollY)
        }

        window.addEventListener('scroll', onScroll, { passive: true })
        return () => {
            isMount = false
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    useEffect(() => {
        if (offset < prevOffset) setShowLinks(true)
        else setShowLinks(false)

        setPrevOffset(offset)
    }, [offset])

    return (
        <header
            className={clsx(classes.header, {
                [classes.transparent]: (transparent || offset < 50) && !hamIsActive,
                [classes.tiny]: offset >= 10,
                [classes.tinyWithLinks]: showLinks,
                [classes.mobileLinksShow]: hamIsActive
            })}>
            <nav role='navigation' className={clsx(classes.mobileLinks, {
                [classes.mobileLinksShow]: hamIsActive
            })}>
                <ul>
                    {links.map(({ path, name }, i) => (
                        <li key={`header_navlink_mobile_${i}`}>
                            <NavLink to={path} className={classes.navlink} onClick={() => setHamIsActive(false)}>
                                {name}
                            </NavLink>
                        </li>
                    ))}
                    <li>
                        <NavLink
                            to='/login'
                            onClick={() => setHamIsActive(false)}
                            className={clsx(classes.navlink)}>
                            {user ? `${Math.round(balance)}₽` : 'Войти'}
                        </NavLink>
                    </li>
                </ul>
            </nav>
            <div className={classes.inner}>
                <div className={classes.location} />
                <div className={classes.logo}>
                    <Link to='/'>
                        <img src={Logo} alt='Parfumer Atelier' />
                    </Link>
                </div>
                <div className={classes.signin}>
                    <Link
                        to='/login'
                        className={clsx('btn', 'btn-login', classes.login)}>
                        <img src={Account} alt="Вход" />
                        {user ? `${Math.round(balance)}₽` : 'Войти'}
                    </Link>
                    <button
                        className={clsx(
                            'hamburger',
                            'hamburger--collapse',
                            classes.ham,
                            {
                                ['is-active']: hamIsActive,
                            }
                        )}
                        type='button'
                        onClick={() => setHamIsActive(!hamIsActive)}>
                        <span className='hamburger-box'>
                            <span className='hamburger-inner' />
                        </span>
                    </button>
                </div>
                <nav role='navigation' className={classes.links}>
                    <ul>
                        {links.map(({ path, name }, i) => (
                            <li key={`header_navlink_${i}`}>
                                <NavLink to={path} className={classes.navlink}>
                                    {name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    )
}

const links = [
    {
        path: '/categories/parfyumeriya',
        name: 'Парфюмерия',
    },
    {
        path: '/categories/aromaboksy',
        name: 'Аромабоксы',
    },
    {
        path: '/brands',
        name: 'Бренды',
    },
    {
        path: '/promo',
        name: 'Акции',
    },
    {
        path: '/contacts',
        name: 'Магазины',
    },
]

export default Header
