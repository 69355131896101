import { ICart } from "../interfaces/Cart";
import { getMinPrice } from "./getMinPrice";

export const getTotalDefaultPrice = (cart: ICart[]) => {
    let total = 0
    cart.forEach(c => {
        const { amount, modifiers, product } = c
        total += modifiers ? modifiers.priceDefault * amount : product.priceDefault * amount
    })
    return total
}

export const getSalesPrice = (cart: ICart[]) => {
    let total = 0
    cart.forEach(c => {
        const { modifiers, amount, product } = c
        total += modifiers ? (modifiers.priceSale === 0 ? 0 : modifiers.priceDefault - modifiers.priceSale) * amount : (product.priceSale === 0 ? 0 : product.priceDefault - product.priceSale) * amount
    })
    return total
}