import Page from "../Page/Page"
import classes from "./Promo.module.css"
import React, {Suspense} from "react";
import {Await, useLoaderData} from "react-router-dom"
import {ISale} from "../../interfaces/ISale"
import {Loader} from "../../components/loader/Loader"
import {ErrorElement} from "../../components/errorElement/ErrorElement"
import {DateTime} from "ts-luxon";
import {Helmet} from "react-helmet-async"

type PromiseType = {
    promo: ISale
}

const SinglePromo = () => {

    const { promo } = useLoaderData() as PromiseType

    return (
        <Page>
            <div className={classes.event}>
                <Suspense fallback={<Loader />}>
                    <Await
                        resolve={promo}
                        errorElement={
                            <ErrorElement msg='Не удалось загрузить хиты' />
                        }>
                        {(p: ISale) => {
                            return (
                                <>
                                    <Helmet prioritizeSeoTags>
                                        <title>{p.title}</title>
                                        <link rel="canonical" href={`https://parfumeratelier.ru/promo/${p.slug}`} />
                                        <meta name="description" content={`Выгодная акция от магазина Parfumer Atelier. ${DateTime.fromISO(p.createdAt).toFormat("dd.LL.y")}`} />
                                    </Helmet>
                                    <div className={classes.header}>
                                        <div className={classes.empty} />
                                        <h2 className={classes.singlePromoTitle}>{p.title}</h2>
                                        <div />
                                    </div>
                                    <div className={classes.singlePromo}>
                                        <img className={classes.singlePromoImage} src={p.image} alt={p.title} />
                                        <div className={classes.singlePromoDates}>
                                            <time>{DateTime.fromISO(p.createdAt).toFormat("dd.LL.y")}</time>
                                        </div>
                                        <div className={classes.empty} />
                                        <div className={classes.singlePromoContent} dangerouslySetInnerHTML={{__html: p.content}} />
                                    </div>
                                </>
                            )}
                        }
                    </Await>
                </Suspense>
            </div>
        </Page>
    )
}

export default SinglePromo