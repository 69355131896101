import React, {Suspense} from 'react'
import Page from "../Page/Page"
import classes from "./Contacts.module.css"
import {IRetailPoint} from "../../interfaces/RetailPoint"
import {Await, useLoaderData} from "react-router-dom"
import {Loader} from "../../components/loader/Loader"
import {ErrorElement} from "../../components/errorElement/ErrorElement"
import RetailPoint from "./RetailPoint"
import NoRetailStore from "../../assets/images/no_retail_store.png"
import {DateTime} from "ts-luxon";
import {Helmet} from "react-helmet-async";

type PromiseType = {
    points: Array<Promise<IRetailPoint>>
}

const Contacts = () => {

    const {points} = useLoaderData() as PromiseType

    return (
        <Page>
            <Helmet prioritizeSeoTags>
                <title>Адреса магазинов</title>
                <link rel="canonical" href="https://parfumeratelier.ru/contacts" />
                <meta name="description" content="Адреса и телефоны магазинов аромабоксов и парфюмерии Parfumer Atelier в разных городах России." />
            </Helmet>
            <div className={classes.contacts}>
                <div className={classes.header}>
                    <div className={classes.empty} />
                    <h2>Контакты</h2>
                    <div />
                </div>

                <Suspense fallback={<Loader />}>
                    <Await
                        resolve={points}
                        errorElement={
                            <ErrorElement msg='Не удалось загрузить точки продаж' />
                        }>
                        {(points: IRetailPoint[]) => {
                            return (
                                <>
                                    {
                                        points.map((p, i) => (
                                            <RetailPoint
                                                key={p._id}
                                                address={
                                                    {
                                                        city: p.addressFull.city,
                                                        street: p.addressFull.street,
                                                        house: p.addressFull.house,
                                                        notice: p.address
                                                    }
                                                }
                                                _id={p._id}
                                                name={p.name}
                                                image={NoRetailStore}
                                                direction={i%2} />
                                        ))
                                    }
                                </>
                            )
                        }}
                    </Await>
                </Suspense>

            </div>
        </Page>
    )
}

export default Contacts
