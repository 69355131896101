import classes from "./footer.module.css"
import {Link} from "react-router-dom"

import MasterCard from "../../assets/images/master_card.png"
import Maestro from "../../assets/images/maestro.png"
import Visa from "../../assets/images/visa.png"
import Mir from "../../assets/images/mir.png"

import YouTube from "../../assets/images/youtube.png"
import Instagram from "../../assets/images/instagram.png"
import Logo from "../../assets/images/logo.png"
import Whatsapp from "../../assets/images/whatsapp.svg"

const Footer = () => {

    return (
        <>
            <div className={classes.preFooter}>
                <div className={classes.inner}>
                    <ul role="navigation" className={classes.left}>
                        {
                            links.map((l, i) => (
                                <li key={`footer_link_${i}`}>
                                    <Link to={l.path}>{l.name}</Link>
                                </li>
                            ))
                        }
                    </ul>
                    <div className={classes.center}>
                        <img src={Logo} alt="Parfumer Atelier" />
                    </div>
                    <div className={classes.right}>
                        <h5>служба поддержки клиентов:</h5>
                        <a href="tel:+74952601003">+7 (495) 260-10-03</a>
                        <a href="https://wa.me/message/M57AZ5K4Y4NRE1" className={classes.wa} target="_blank" rel="noopener noreferrer nofollow">
                            <img src={Whatsapp} alt="WhatsApp" />
                            WhatsApp
                        </a>
                        <br/>
                        <Link to="/documents">документы сайта</Link>
                        <Link to="/documents">политика обработки персональных данных</Link>
                    </div>
                </div>
            </div>
            <footer className={classes.footer}>
                <div className={classes.inner}>
                    <div className={classes.social}>
                        <a href="https://www.instagram.com/parfumer_atelier_kld" target="_blank" rel="noopener nofollow norefferer">
                            <img src={Instagram} alt="Parfumer Atelier Instagram" />
                        </a>
                        <a href="https://www.youtube.com/@parfumeratelier7364" target="_blank" rel="noopener nofollow norefferer">
                            <img src={YouTube} alt="Parfumer Atelier Youtube" />
                        </a>
                    </div>
                    <p></p>
                    <div className={classes.paymentTypes}>
                        <img src={Visa} alt="Parfumer Atelier принимает к оплате карты Visa" />
                        <img src={MasterCard} alt="Parfumer Atelier принимает к оплате карты MasterCard" />
                        <img src={Maestro} alt="Parfumer Atelier принимает к оплате карты Maestro" />
                        <img src={Mir} alt="Parfumer Atelier принимает к оплате карты Мир" />
                    </div>
                    <div className={classes.copyright}>
                        <span>Parfumer Atelier 2023</span>
                    </div>
                </div>
            </footer>
        </>
    )
}

const links = [
    {
        path: '/categories/parfyumeriya',
        name: 'Парфюмерия',
    },
    {
        path: '/categories/aromaboksy',
        name: 'Аромабоксы',
    },
    {
        path: '/brands',
        name: 'Бренды',
    },
    {
        path: '/promo',
        name: 'Акции',
    },
    {
        path: '/contacts',
        name: 'Магазины',
    },
]

export default Footer