import React, {useState, useRef} from 'react'
import classes from './slider.module.css'
import {Link} from "react-router-dom"
import clsx from "clsx"
import useIsMounted from "../../hooks/useIsMounted"
import {ISlide} from "../../interfaces/Slider"

type Props = {
    slides: Array<ISlide>
}

const enum Direction {
    Left,
    Right,
    Middle
}

const Slider: React.FC<Props> = ({ slides }) => {

    const [cursor, setCursor] = useState(Direction.Middle)
    const [index, setIndex] = useState(0)
    const sliderRef = useRef<HTMLDivElement>(null)

    const width = window.innerWidth
    const leftWidth = width / 3
    const rightWidth = width - leftWidth

    const onMouseMove = (e: React.MouseEvent) => {
        const { clientX } = e
        if (clientX > 0 && clientX <= leftWidth) {
            setCursor(Direction.Left)
        } else if (clientX >= rightWidth && clientX < width) {
            setCursor(Direction.Right)
        } else {
            setCursor(Direction.Middle)
        }
    }

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault()
        let i = index
        if (cursor === Direction.Left) {
            i = i > 0 ? --i : 0
            if (sliderRef.current !== null) {
                sliderRef.current.style.transform = `translateX(${i*-width}px)`
            }
        } else if (cursor === Direction.Right) {
            i = i < slides.length - 1 ? ++i : slides.length - 1
            if (sliderRef.current !== null) {
                sliderRef.current.style.transform = `translateX(${i*-width}px)`
            }
        }
        setIndex(i)
    }

    const isMounted = useIsMounted()

    return (
        <div className={clsx(classes.slider, {
            [classes.sliderCursorLeft]: cursor === Direction.Left,
            [classes.sliderCursorRight]: cursor === Direction.Right
        })} onMouseMove={onMouseMove}>
            <div ref={sliderRef}
                 className={clsx(classes.slides, {[classes.mounted]: isMounted})}
                 style={{width: `${width * slides.length}px`}}>
            {slides.map((s, i) => (
                <div
                    key={`slide_${i}`}
                    className={classes.slide}
                    onClick={onClick}
                    style={{ backgroundImage: `url("${s.img}")`, width }}>
                    <div className={classes.content}>
                        <h2>{s.title}</h2>
                        <p>{s.subtitle}</p>
                        {
                            s.action.url.includes("http") ? (
                                <a
                                    href={s.action.url}
                                    className={classes.aBtn}
                                    onMouseOver={(e) => {e.currentTarget.style.backgroundColor = s.action.color; e.currentTarget.style.color = "white"}}
                                    onMouseOut={(e) => {e.currentTarget.style.backgroundColor = "transparent"; e.currentTarget.style.color = s.action.color}}
                                    style={{color: s.action.color, borderColor: s.action.color}}
                                    target="_blank"
                                    onClick={() => window.open(s.action.url)}
                                    rel="nofollow noopener noreferrer">
                                    {s.action.title}
                                </a>
                            ) : (
                                <Link
                                    to={s.action.url}
                                    className={classes.aBtn}
                                    onMouseOver={(e) => {e.currentTarget.style.backgroundColor = s.action.color; e.currentTarget.style.color = "white"}}
                                    onMouseOut={(e) => {e.currentTarget.style.backgroundColor = "transparent"; e.currentTarget.style.color = s.action.color}}
                                    style={{color: s.action.color, borderColor: s.action.color}}>
                                    {s.action.title}
                                </Link>
                            )
                        }
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default Slider
export type { ISlide }
