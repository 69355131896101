import React from 'react'
import {Header, Footer, MiniCart, ScrollToTop} from '../../components'
import classes from './Page.module.css'
import {Helmet} from "react-helmet-async"

type Props = {
    children: React.ReactNode
    transparentHeader?: boolean
    hideMiniCart?: boolean
}

const Page: React.FC<Props> = ({
    children,
    transparentHeader = false,
    hideMiniCart = false,
}) => {
    return (
        <>
            <Helmet prioritizeSeoTags titleTemplate={`%s - Parfumer Atelier`}>
                <title>Интернет-магазин парфюмерии</title>
                <link rel="canonical" href="https://parfumeratelier.ru" />
            </Helmet>
            <ScrollToTop />
            <Header transparent={transparentHeader} />
            <main className={classes.main}>{children}</main>
            <Footer />
            {!hideMiniCart && <MiniCart />}
        </>
    )
}

export default Page
