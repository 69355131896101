import React, {useMemo, useRef, useState, FC} from 'react'
import classes from '../../pages/Cart/Cart.module.css'
import { priceWithSpace } from '../../utils/priceWithSpace'
import clsx from 'clsx'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { getTotalDefaultPrice, getSalesPrice } from '../../utils/getTotalPrice'
import { useNavigate } from 'react-router-dom'
import { cartActions } from '../../store/reducers/cartReducer'
import {PromocodeService} from "../../api/PromocodeService"
import {OrderService} from "../../api/OrderService"
import {IOrder, IProcessSale} from "../../interfaces/Order"
import {SetPromocodeType} from "../../pages/Cart/Cart"
import {useDispatch} from "react-redux";

export const CartCard: FC<SetPromocodeType> = ({ setPromocodeResp }) => {
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const { user } = useTypedSelector((state) => state.userReducer)

    const dispatch = useDispatch()

    const promoTextRef = useRef<HTMLParagraphElement>(null)

    const [promocode, setPromocode] = useState("")
    const [isPromoActive, setIsPromoActive] = useState(false)
    const [promocodeDiscount, setPromocodeDiscount] = useState(-1)

    const promocodeService = new PromocodeService()
    const orderService = new OrderService()
    const navigate = useNavigate()

    const defaultPrice = useMemo(() => {
        return getTotalDefaultPrice(cart)
    }, [cart])

    const salesPrice = useMemo(() => {
        return getSalesPrice(cart)
    }, [cart])

    const goOrder = () => {
        if(user) navigate('/order')
        else navigate('/login')
    }

    const onPromoClick = () => {
        if (promoTextRef && promoTextRef.current) {
            promoTextRef.current.innerText = ""
            promoTextRef.current.classList.remove("add")
            promoTextRef.current.classList.remove("error")
            promoTextRef.current.classList.remove("hidden")

            promocodeService.checkPromocode(promocode)
                .then((data) => {
                    if (promoTextRef && promoTextRef.current) {
                        if (data.result_code !== "0") {
                            promoTextRef.current.classList.add("error")
                            setPromocodeResp(undefined)
                            setPromocodeDiscount(-1)
                        }

                        if (data.result_code === "0") {
                            // dispatch(cartActions.setPromocode([promocode, promocodeDiscount]))
                            promoTextRef.current.classList.add("success")
                            // Process sale
                            const order: IOrder = {
                                sourceId: "639c1b3a1ebccb09c5beb660",
                                promocode,
                                check: true,
                                bonusOut: "0"
                            }
                            orderService.processSale(order)
                                .then((data) => {
                                    dispatch(cartActions.setPromocode([promocode, data._bill_data.discount]))
                                    setPromocodeDiscount(data._bill_data.discount)
                                    setPromocodeResp(data)
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }

                        promoTextRef.current.innerText = data.result_text
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <div className={classes.detail}>
            <h2>Детали заказа</h2>
            <div className={classes.attrs}>
                <dl className={classes.tags}>
                    <div className={classes.tag}>
                        <dt className={classes.tagKey}>
                            <span>
                                {cart.reduce(
                                    (total, c) => (total += c.amount),
                                    0
                                )}{' '}
                                товара
                            </span>
                        </dt>
                        <dd className={classes.tagValue}>
                            <span>{priceWithSpace(defaultPrice)}</span>
                        </dd>
                    </div>
                    <div className={classes.tag}>
                        <dt className={classes.tagKey}>
                            <span>Скидка</span>
                        </dt>
                        <dd className={classes.tagValue}>
                            <span>{priceWithSpace(-salesPrice)}</span>
                        </dd>
                    </div>
                    {
                        promocodeDiscount >= 0 ? (
                            <div className={classes.tag}>
                                <dt className={classes.tagKey}>
                                    <span>Промокод <strong>{promocode}</strong></span>
                                </dt>
                                <dd className={classes.tagValue}>
                                    <span>{-promocodeDiscount}</span>
                                </dd>
                            </div>
                        ) : null
                    }
                    <div className={clsx(classes.tag, classes.tagTotal)}>
                        <dt className={classes.tagKey}>
                            <span>Итого</span>
                        </dt>
                        <dd className={classes.tagValue}>
                            <span>
                                {priceWithSpace(promocodeDiscount >= 0 ? defaultPrice - salesPrice - promocodeDiscount : defaultPrice - salesPrice)}
                            </span>
                        </dd>
                    </div>
                </dl>
            </div>

            <button className={clsx("btn", {[classes.hidden]: isPromoActive})} onClick={() => setIsPromoActive(true)}>Есть промокод?</button>
            <div className={clsx(classes.promoBlock, {[classes.hidden]: !isPromoActive})}>
                <input type="text" className="input" placeholder="Промокод" value={promocode} onChange={({target:{value}}) => setPromocode(value)} />
                <button className='btn' onClick={onPromoClick}>Применить</button>
            </div>
            <p ref={promoTextRef} className={clsx(classes.promoText, "hidden")}>Проверяем промокод...</p>
            <button className='btn' style={{marginTop: "1rem"}} onClick={goOrder}>
                Перейти к оформлению
            </button>
        </div>
    )
}
