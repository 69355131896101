import { createBrowserRouter, RouterProvider, defer } from 'react-router-dom'
import { BrandService } from './api/BrandService'
import { ProductService } from './api/ProductService'
import { TagService } from './api/TagService'

import * as Pages from './pages'
import { CategoryService } from './api/CategoryService'
import { CustomerService } from './api/CustomerService'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from './store/reducers/userReducer'
import {SliderService} from "./api/SliderService"
import {RetailPointService} from "./api/RetailPointService"
import { backCartToFrontCart } from './utils/BackendCartToFrontCart'
import { cartActions } from './store/reducers/cartReducer'
import {DeliveryTypeService} from "./api/DeliveryType"
import {SaleService} from "./api/Sale"
import {ISale} from "./interfaces/ISale"

const router = createBrowserRouter([
    {
        path: '/',
        index: true,
        element: <Pages.Main />,
        errorElement: <Pages.NotFound />,
        loader: async () => {
            const promoApi = new SaleService()
            const brandApi = new BrandService()
            const sliderApi = new SliderService()
            const brands = await brandApi.getBrandsForMainPage()
            const productApi = new ProductService()
            const hitProducts = productApi.getProductsByBadge('hit')
            const newProducts = productApi.getProductsByBadge('new')
            const slider = sliderApi.getSlider()
            const promos = promoApi.get()

            // TODO: add requests: news, stories
            return defer({ brands, slider, hitProducts, newProducts, promos })
        },
    },
    {
        path: '/cart',
        index: true,
        element: <Pages.Cart />,
        errorElement: <Pages.NotFound />,
        loader: async () => {
            //TODO: rework to featured
            const productApi = new ProductService()
            const hitProducts = productApi.getProductsByBadge('hit')

            return defer({ hitProducts })
        },
    },
    {
        path: '/documents',
        index: true,
        element: <Pages.Documents />,
        errorElement: <Pages.NotFound />
    },
    {
        path: '/promo',
        element: <Pages.Promo />,
        errorElement: <Pages.NotFound />,
        index: true,
        loader: async ({params: {slug}}) => {
            const promoApi = new SaleService()
            const promos = promoApi.get()
            return defer({promos})
        },
    },
    {
        path: '/promo/:slug',
        index: true,
        element: <Pages.SinglePromo />,
        errorElement: <Pages.NotFound />,
        loader: async ({ params: { slug } }) => {
            const promoApi = new SaleService()
            const promos = await promoApi.get()
            const promo = promos.find((p) => p.slug === slug)

            return defer({ promo })
        },
    },
    {
        path: '/order',
        index: true,
        element: <Pages.Order />,
        errorElement: <Pages.NotFound />,
        loader: async () => {
            const dtService = new DeliveryTypeService()
            const deliveryTypes = dtService.get()

            const rpService = new RetailPointService()
            const retailPoints = rpService.get()

            return defer({deliveryTypes, retailPoints})
        }
    },
    {
        path: '/login',
        index: true,
        element: <Pages.Login />,
        errorElement: <Pages.NotFound />,
    },
    {
        path: '/signup',
        index: true,
        element: <Pages.Signup />,
        errorElement: <Pages.NotFound />,
    },
    {
        path: '/account',
        element: <Pages.Account />,
        errorElement: <Pages.NotFound />,
        loader: async () => {
            const api = new CustomerService()
            const customer = api.getProfile()
            const balance = api.getBalance()
            return defer({ customer, balance })
        },
        children: [
            {
                path: 'profile',
                element: <Pages.AccountProfile />,
                loader: async ({ params: { slug } }) => {
                    //TODO: load profile

                    return defer({})
                },
            },
            {
                path: 'favorite',
                element: <Pages.AccountFavorite />,
                loader: async ({ params: { slug } }) => {
                    const api = new CustomerService()

                    return defer({
                        favorite: api.getFavorite(),
                    })
                },
            },
            {
                path: 'orders',
                element: <Pages.AccountOrders />,
                loader: async ({ params: { slug } }) => {
                    //TODO: load orders
                    return defer({})
                },
            },
            {
                path: 'settings',
                element: <Pages.AccountSettings />,
                loader: async ({ params: { slug } }) => {
                    //TODO: load settings
                    return defer({})
                },
            },
        ],
    },
    {
        path: '/categories',
        element: <Pages.Catalog />,
        errorElement: <Pages.NotFound />,
        loader: async () => {
            // Fetch groups tags
            const api = new TagService()

            // First 20 brand's products
            return defer({
                items: 'items',
                groups: api.getTagGroups(),
                dataType: 'brand',
            })
        },
        children: [
            {
                path: ':slug',
                element: <Pages.Items />,
                loader: async ({ params: { slug } }) => {
                    // Fetch brand by slug
                    const categoryApi = new CategoryService()
                    const category = categoryApi.getCategories(slug)

                    // Fetch products by brand slug
                    const productApi = new ProductService()
                    const items = productApi.getProductsByCategory(slug)
                    return defer({
                        category,
                        items,
                        dataType: 'category',
                    })
                },
            },
        ],
    },
    {
        path: '/brands',
        element: <Pages.Catalog />,
        errorElement: <Pages.NotFound />,
        loader: async () => {
            // Fetch groups tags
            const api = new TagService()
            const brandApi = new BrandService()

            return defer({
                items: 'items',
                brands: brandApi.getBrands(),
                groups: api.getTagGroups(),
                dataType: 'brand',
            })
        },
        children: [
            {
                path: ':slug',
                element: <Pages.Items />,
                loader: async ({ params: { slug } }) => {
                    // Fetch brand by slug
                    const brandApi = new BrandService()
                    const brand = brandApi.getBrands(slug)

                    // Fetch products by brand Slug
                    const productApi = new ProductService()
                    const items = productApi.getProductsByBrand(slug)
                    return defer({
                        brand,
                        items,
                        dataType: 'brand',
                    })
                },
            },
        ],
    },
    {
        path: '/products',
        errorElement: <Pages.NotFound />,
        children: [
            {
                path: ':slug',
                element: <Pages.Item />,
                loader: async ({ params: { slug } }) => {
                    // Fetch product by slug
                    const productApi = new ProductService()
                    const items = productApi.getProductBySlug(slug)
                    return defer({
                        items,
                        dataType: 'item',
                    })
                },
            },
        ],
    },
    {
        path: 'contacts',
        index: true,
        element: <Pages.Contacts />,
        // errorElement: <Pages.NotFound />,
        loader: async () => {
            const retailPointsApi = new RetailPointService()
            const points = retailPointsApi.get()
            return(defer({points}))
        }
    }
])

const App = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const getProfile = async () => {
            try {
                const api = new CustomerService()
                const profile = await api.getProfile()
                dispatch(userActions.setUser(profile || null))
                const cart = await backCartToFrontCart(profile.cart.products)
                dispatch(cartActions.setCart(cart))
            } catch (e) {
                console.log(e)
                dispatch(userActions.setUser(null))
            }
        }
        getProfile()
    }, [])

    return <RouterProvider router={router} />
}

export default App
