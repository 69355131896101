import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICart } from "../../interfaces/Cart"
import { CustomerService } from "../../api/CustomerService"


interface CartState {
    cart: ICart[]
    promocode: string
    promocodePrice: number
}

const customerService = new CustomerService()

const initialState: CartState = {
    cart: [],
    promocode: "",
    promocodePrice: 0
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setPromocode: (state, action: PayloadAction<[string, number]>) => {
            state.promocode = action.payload[0]
            state.promocodePrice = action.payload[1]
        },
        setCart: (state, action: PayloadAction<ICart[]>) => {
            state.cart = action.payload
        },
        addToCart: (state, action: PayloadAction<ICart>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => {
                if (i.product._id !== action.payload.product._id) return false
                //TODO: Потом нормально сравнивать массивы модификаторов
                if (!i.modifiers && !action.payload.modifiers) return true
                if (i.modifiers && action.payload.modifiers && i.modifiers.slug === action.payload.modifiers.slug) return true
                return false
            })

            if (itemsIndexInCartForAdd < 0) {
                state.cart = [...state.cart, action.payload]
            } else {
                state.cart = state.cart.map((c, i) => {
                    if (i === itemsIndexInCartForAdd) c.amount++
                    return c
                })
            }

            customerService.sendCart(state.cart).then()
        },
        increaseCount: (state, action: PayloadAction<string>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => i._id === action.payload)

            if (itemsIndexInCartForAdd !== -1) {
                state.cart = state.cart.map((c, i) => {
                    if (i === itemsIndexInCartForAdd) c.amount++
                    return c
                })
            }

            customerService.sendCart(state.cart).then()
        },
        decreaseCount: (state, action: PayloadAction<string>) => {
            const itemsIndexInCartForAdd = state.cart.findIndex(i => i._id === action.payload)
            if (itemsIndexInCartForAdd !== -1) {
                state.cart = state.cart.map((c, i) => {
                    if (i === itemsIndexInCartForAdd) c.amount--
                    return c
                }).filter(c => c.amount !== 0)
            }

            customerService.sendCart(state.cart).then()
        },
        clear: (state) => {
            state.cart = []
            customerService.sendCart([]).then()
        },
    }
})


export const cartReducer = cartSlice.reducer
export const cartActions = cartSlice.actions