import React from 'react'
import ReactDOM from 'react-dom/client'
import WebFont from 'webfontloader'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import 'hamburgers/dist/hamburgers.min.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { CookiesProvider } from "react-cookie"

WebFont.load({
    custom: {
        families: ['Stem'],
    },
    events: true,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>
)
