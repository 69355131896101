import Page from '../Page/Page'
import "swiper/swiper.min.css"
import {Helmet} from "react-helmet-async"
import Stories, {ContentProps, StoryProps,} from '../../components/stories/stories'
import gClasses from '../Page/Page.module.css'
import classes from "./Main.module.css"
import {BrandGroup, H, ItemsGroup, Slider, SliderGroup} from "../../components"
import {Loader} from "../../components/loader/Loader"
import {Await, useLoaderData} from "react-router-dom"
import {ErrorElement} from "../../components/errorElement/ErrorElement"
import React, {Suspense} from "react"
import {IBrand} from "../../interfaces/Brand"
import {IItemValue} from "../../interfaces/Item"

import Test1 from "../../assets/images/test1.png"
import Test2 from "../../assets/images/test2.png"

import Test3 from "../../assets/images/news1.png"
import Test4 from "../../assets/images/news2.png"
import {ISlide, ISlider} from "../../interfaces/Slider"
import {ISale} from "../../interfaces/ISale";

type PromiseType = {
    brands: Array<Promise<IBrand>>
    hitProducts: Promise<IItemValue>
    newProducts: Promise<IItemValue>
    slider: Promise<ISlider>
    promos: Array<Promise<ISale>>
}

const Main = () => {

    const {brands, hitProducts, newProducts, slider, promos} = useLoaderData() as PromiseType

    return (
        <Page>
            <Suspense fallback={<Loader />}>
                <Await
                    resolve={slider}
                    errorElement={
                        <ErrorElement msg='Не удалось загрузить новинки' />
                    }>
                    {(sliders: Array<ISlider>) => {
                        return (
                            <Slider slides={sliders[0].slides} />
                        )}
                    }
                </Await>
            </Suspense>

            <div className={gClasses.inner}>
                {/*<Stories stories={stories} />*/}
                {/*<div style={{margin: "2rem 0"}} />*/}
                <Suspense fallback={<Loader />}>
                    <Await
                        resolve={newProducts}
                        errorElement={
                            <ErrorElement msg='Не удалось загрузить новинки' />
                        }>
                        {(bundles: IItemValue) => {
                            return (
                                <ItemsGroup title="Новинки" items={bundles.values} perPage={3} />
                            )}
                        }
                    </Await>
                </Suspense>

                <div style={{margin: "2rem 0"}} />
                <SliderGroup title="Акции" content={promos} perPage={1} />

                <div style={{margin: "2rem 0"}} />
                <Suspense fallback={<Loader />}>
                    <Await
                        resolve={hitProducts}
                        errorElement={
                            <ErrorElement msg='Не удалось загрузить хиты' />
                        }>
                        {(bundles: IItemValue) => {
                            return (
                                <ItemsGroup title="Хиты" items={bundles.values} perPage={3} />
                            )}
                        }
                    </Await>
                </Suspense>

                <div style={{margin: "2rem 0"}} />
                <H title="Бренды" vSpacing="2rem" />
                <div className={classes.brands}>
                {
                    brands.map((p, i) => (
                        <Suspense key={`promise_${i}`} fallback={<Loader />}>
                            <Await
                                resolve={p}
                                errorElement={
                                    <ErrorElement msg='Не удалось загрузить бренды' />
                                }>
                                {(b: IBrand) => {
                                    return (
                                        <BrandGroup
                                            _id={b._id}
                                            key={b._id}
                                            name={b.name}
                                            path={`/brands/${b.slug}`}
                                            image={b.imgUrl[0]}
                                            items={b.products}
                                            direction={i%2} />
                                    )}
                                }
                            </Await>
                        </Suspense>
                    ))
                }
                </div>

                <div style={{margin: "2rem 0"}} />
                {/*<SliderGroup title="Новости" slides={[Test3, Test4]} />*/}

            </div>
        </Page>
    )
}

const content: ContentProps = {
    contentType: 'image',
    content: '',
}

const story1: StoryProps = {
    preview: '',
    contents: [content],
}

const story2: StoryProps = {
    preview:
        'https://storage.yandexcloud.net/parfum.public/stories/story_cover_21.07.22_okolo.jpg',
    contents: [content],
}

const story3: StoryProps = {
    preview:
        'https://storage.yandexcloud.net/parfum.public/stories/story_cover_21.07.22_okolo.jpg',
    contents: [content],
}

const story4: StoryProps = {
    preview:
        'https://storage.yandexcloud.net/parfum.public/stories/story_cover_21.07.22_okolo.jpg',
    contents: [content],
}

const stories: Array<StoryProps> = [story1, story2, story3, story4]

export default Main
