import React, { useEffect, useState, useRef, createRef } from 'react'
import { ITagGroup, ITag } from '../../interfaces/TagGroup'
import classes from './sidebar.module.css'
import NumberSlider from '../numberSlider/numberSlider'
import Arrow from '../../assets/images/arrow_cat.svg'
import clsx from 'clsx'

type Props = {
    groups: Array<ITagGroup>
    onFilterChange: (filter: ITag[]) => void
    onPriceFilterChange: ([min, max]: number[]) => void
}

interface IGroup {
    id: string
    isOpen: boolean
}

const Sidebar: React.FC<Props> = ({
    groups,
    onFilterChange,
    onPriceFilterChange,
}) => {
    const refs = useRef(
        Array.from({ length: groups.length }, (a) =>
            createRef<HTMLDivElement>()
        )
    )
    const [groupDivs, setGroupDivs] = useState<Array<IGroup>>([])

    useEffect(() => {
        let groupsArr = []
        for (let i of groups) {
            groupsArr.push({ id: i.slug, isOpen: true })
        }
        setGroupDivs(groupsArr)
    }, [groups])

    const onGroupHeadClick = (e: React.MouseEvent, slug: string) => {
        const newGroups = groupDivs.map((g) => {
            if (g.id === slug) {
                g.isOpen = !g.isOpen
            }
            return g
        })

        setGroupDivs(newGroups)
    }

    const checkOpen = (slug: string): boolean => {
        const div = groupDivs.find((t) => t.id === slug)
        if (div) return !div.isOpen
        return false
    }

    const [filterTags, setFilterTags] = useState<ITag[]>([])
    const [filterPrice, setFilterPrice] = useState<number[]>([-1, -1])
    const addFilterTag = (tag: ITag) => {
        setFilterTags((prev) => [...prev, tag])
    }
    const removeFilterTag = (tag: ITag) => {
        setFilterTags((prev) => prev.filter((p) => tag._id !== p._id))
    }

    return (
        <nav role='menu' className={classes.sidebar}>
            <NumberSlider onChange={(min, max) => setFilterPrice([min, max])} />
            {groups.map((g, i) => {
                return g._id === "6391c74fe710367b407b80e0" || (g.isDeleted || g.isDisabled)  ? null : (
                <div
                    key={g._id}
                    ref={refs.current[i]}
                    className={clsx(classes.group, {
                        [classes.closed]: checkOpen(g.slug),
                    })}>
                    <div
                        className={classes.groupHead}
                        onClick={(e) => onGroupHeadClick(e, g.slug)}>
                        <h4>{g.name}</h4>
                        <img src={Arrow} alt='Свернуть/Раскрыть'/>
                    </div>
                    <div id={g.slug} className={classes.groupTags}>
                        {g.tags.map((t: ITag) => (
                            <div key={t._id} className={classes.groupTag}>
                                <input
                                    type='checkbox'
                                    className="checkbox"
                                    id={`${g.slug}+${t.slug}`}
                                    name={g.slug}
                                    value={t.slug}
                                    onChange={(e) => {
                                        if (e.target.checked) addFilterTag(t)
                                        else removeFilterTag(t)
                                    }}
                                />
                                <label htmlFor={`${g.slug}+${t.slug}`}>
                                    {t.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                )
            })}
            <button
                className={classes.applyBtn}
                onClick={() => {
                    onFilterChange(filterTags)
                    onPriceFilterChange(filterPrice)
                }}>
                Применить
            </button>
        </nav>
    )
}

export default Sidebar
