import React, {SyntheticEvent} from "react"
import { Link } from "react-router-dom"
import clsx from "clsx"
import classes from "./item.module.css"
import NoItem from "../../assets/images/no_item.png"
import {IBadge} from "../../interfaces/Badge"
import {ICategory} from "../../interfaces/Category"

interface IItem {
    image: string
    name: string
    price: number
    categories: Array<ICategory>
    salesPrice?: number
    isSmall?: boolean
    slug: string
    isBundle?: boolean
    badges?: Array<IBadge>
}

const Item: React.FC<IItem> = ({image, slug, name, price, categories, salesPrice, badges = [], isSmall = false, isBundle = false}) => {

    const onError = (e: SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.src = NoItem
    }

    const priceWithSpace = (p: Number) => p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

    return (
        <Link to={`/products/${slug}`} className={classes.itemWrapper}>
            <figure className={clsx(classes.item, {[classes.itemSmall]: isSmall})}>
                <div className={classes.badges}>
                    {
                        badges.map((b) => (
                            <img key={b._id} src={b.imgUrl[0] || NoItem} alt={b.name} onError={onError} />
                        ))
                    }
                </div>
                <img src={image || NoItem} alt={name} onError={onError} className={clsx(classes.image, {[classes.imageSmall]: isSmall})} />
                <div className={clsx(classes.content)}>
                    <div className={classes.block}>
                        <span className={classes.category}>{categories && categories[0] && categories[0].name}</span>
                        <figcaption className={classes.name}>{name}</figcaption>
                    </div>
                    <div className={classes.price}>
                        {
                            salesPrice && salesPrice > 0 ? (
                                <>
                                    <span className={classes.regularPrice}>{priceWithSpace(salesPrice)}</span>
                                    <span className={classes.crossedPrice}>{priceWithSpace(price)}</span>
                                </>
                            ) : (
                                <span className={classes.regularPrice}>{priceWithSpace(price)}</span>
                            )
                        }
                    </div>
                </div>
            </figure>
        </Link>
    )
}

export default Item
export type { IItem }
