import { PayloadAction } from '@reduxjs/toolkit'
import React, { useEffect, useReducer, useRef } from 'react'
import { useOutletContext } from 'react-router-dom'
import { DateTime } from 'ts-luxon'
import { CustomerService } from '../../api/CustomerService'
import { ICustomer } from '../../interfaces/Customer'
import { CustomerContextType } from './Main'
import classes from './Profile.module.css'

const initState: ICustomer = {
    _id: '',
    wallet: {
        auth: '',
        card: '',
    },
    sex: '',
    pushTokens: [],
    phone: '',
    firstName: '',
    middleName: '',
    lastName: '',
    googleWallet: '',
    email: '',
    card: 0,
    birthDate: '',
    bonusData: {
        category: '',
        balance: 0,
    },
    cart: {
        products: []
    }
}

const reducer = (state: ICustomer, action: any): ICustomer => {
    switch (action.type) {
        case 'setstate':
            return {
                ...action.payload,
                birthDate: new Date(
                    action.payload.birthDate
                ).toLocaleDateString(),
            }
        case 'setfirstname':
            return { ...state, firstName: action.payload }
        case 'setmiddlename':
            return { ...state, middleName: action.payload }
        case 'setlastname':
            return { ...action.payload, lastName: action.payload }
        case 'setsex':
            return { ...state, sex: action.payload }
        case 'setbday':
            return { ...state, birthDate: action.payload }
        case 'setemail':
            return { ...state, email: action.payload }
        case 'setphone':
            return { ...state, phone: action.payload }
        default:
            return state
    }
}

const Profile = () => {
    const { customer } = useOutletContext<CustomerContextType>()
    const [state, dispatch] = useReducer(reducer, initState)

    const btnSaveRef = useRef<HTMLButtonElement>(null)
    useEffect(() => {
        if (customer) {
            dispatch({
                type: 'setstate',
                payload: customer,
            })
        }
    }, [])

    const onInputChange = (value: string, type: string) => {
        dispatch({
            type,
            payload: value,
        })
    }

    const saveProfile = async () => {
        const {
            firstName,
            lastName,
            email,
            middleName,
            sex,
            phone,
            birthDate,
        } = state
        try {
            if (btnSaveRef.current) btnSaveRef.current.disabled = true
            const api = new CustomerService()
            const res = await api.editProfile({
                firstName,
                lastName,
                sex,
                birthDate:
                    DateTime.fromFormat(birthDate, 'dd.MM.yyyy').toISO() || '',
                email,
                restrictions: {
                    sms: true,
                    email: true,
                    push: true,
                },
            })
            console.log(res)
        } catch (e) {
            console.log(e)
        } finally {
            if (btnSaveRef.current) btnSaveRef.current.disabled = false
        }
    }
    return (
        <div>
            <h2>Личная информация</h2>
            <form className={classes.form}>
                <input
                    type='text'
                    className='input'
                    placeholder='Имя'
                    value={state.firstName}
                    onChange={(e) =>
                        onInputChange(e.target.value, 'setfirstname')
                    }
                />
                <input
                    type='text'
                    className='input'
                    placeholder='Фамилия'
                    value={state.lastName}
                    onChange={(e) =>
                        onInputChange(e.target.value, 'setlastname')
                    }
                />
                <input
                    type='text'
                    className='input'
                    placeholder='Отчество'
                    value={state.middleName || ''}
                    onChange={(e) =>
                        onInputChange(e.target.value, 'setmiddlename')
                    }
                />

                <div className={classes.sex}>
                    <div className={classes.groupTag}>
                        <input
                            type='radio'
                            className={classes.checkbox}
                            id='sex-male'
                            name='sex'
                            value='male'
                            checked={state.sex === 'male'}
                            onChange={(e) => {
                                dispatch({
                                    type: 'setsex',
                                    payload: 'male',
                                })
                            }}
                        />
                        <label htmlFor='sex-male'>Мужской</label>
                    </div>

                    <div className={classes.groupTag}>
                        <input
                            type='radio'
                            className={classes.checkbox}
                            id='sex-female'
                            name='sex'
                            value='female'
                            checked={state.sex === 'female'}
                            onChange={(e) => {
                                dispatch({
                                    type: 'setsex',
                                    payload: 'female',
                                })
                            }}
                        />
                        <label htmlFor='sex-female'>Женский</label>
                    </div>

                    <div className={classes.groupTag}>
                        <input
                            type='radio'
                            className={classes.checkbox}
                            id='sex-unspecified'
                            name='sex'
                            value='unspecified'
                            checked={state.sex === 'unspecified'}
                            onChange={(e) => {
                                dispatch({
                                    type: 'setsex',
                                    payload: 'unspecified',
                                })
                            }}
                        />
                        <label htmlFor='sex-unspecified'>Не указан</label>
                    </div>
                </div>

                <input
                    type='text'
                    className='input'
                    placeholder='Дата рождения'
                    value={state.birthDate}
                    onChange={(e) => onInputChange(e.target.value, 'setbday')}
                />
                <input
                    type='email'
                    className='input'
                    placeholder='E-mail'
                    value={state.email}
                    onChange={(e) => onInputChange(e.target.value, 'setemail')}
                />
                <input
                    type='tel'
                    className='input'
                    placeholder='Телефон'
                    value={state.phone}
                    onChange={(e) => onInputChange(e.target.value, 'setphone')}
                />
                <button ref={btnSaveRef} className='btn' onClick={saveProfile}>
                    Сохранить
                </button>
            </form>
        </div>
    )
}

export default Profile
