import classes from './miniCart.module.css'
import { Link } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useMemo } from 'react'
import { getSalesPrice, getTotalDefaultPrice } from '../../utils/getTotalPrice'
import {ICart} from "../../interfaces/Cart"

const MiniCart = () => {
    const { cart } = useTypedSelector((state) => state.cartReducer)
    const defaultPrice = useMemo(() => {
        return getTotalDefaultPrice(cart)
    }, [cart])

    const salesPrice = useMemo(() => {
        return getSalesPrice(cart)
    }, [cart])

    const getModifierName = (c: ICart) => {
        return c.modifiers ? c.modifiers.name : ""
    }

    if (cart.length === 0) return null

    return (
        <div className={classes.miniCart}>
            <div className={classes.inner}>
                <div className={classes.lastItem}>
                    <img
                        src={cart[cart.length - 1].product.imgUrl[0] || ''}
                        alt={cart[cart.length - 1].product.name}
                        className={classes.image}
                    />
                    <div className={classes.name}>
                        <span>{cart[cart.length - 1].product.name}</span>
                        <span>{getModifierName(cart[cart.length - 1])} мл</span>
                    </div>
                </div>
                <div className={classes.cart}>
                    <Link to='/cart' className={classes.cartBtn}>
                        Корзина
                    </Link>
                    <span className={classes.price}>
                        {defaultPrice - salesPrice}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default MiniCart
