import { ICart } from '../interfaces/Cart'
import { ICustomer } from '../interfaces/Customer'
import { Api } from './Api'
import { axiosInstance } from './axios'

interface IProfile {
    firstName: string
    lastName: string
    sex: string
    email: string
    birthDate: string
    restrictions: {
        sms: boolean
        email: boolean
        push: boolean
    }
}

export interface IBackendCart {
    productId: string
    modifierArticle: string
    type: string
    quantity: number
}

export interface IBalance {
    balance: string
    begin_dt?: string
    expire_dt?: string
}

//TODO: ADD TYPES
export class CustomerService extends Api {
    protected PATH = 'v1/customer'
    getBalance = async () => {
        const req = await axiosInstance.get(`${this.URL}/${this.PATH}/balance`)
        let balance = 0
        for (let [_, data] of Object.entries(req.data)) {
            if(data && typeof data === "object" && data.hasOwnProperty("balance")) {
                const row = <IBalance> data
                balance += parseFloat(row.balance)
            }
        }
        return balance
    }
    getMoves = async () => {
        const req = await axiosInstance.get(`${this.URL}/${this.PATH}/moves`)
        return req.data
    }
    getProfile = async () => {
        const req = await axiosInstance.get<ICustomer>(`${this.URL}/${this.PATH}/profile`)
        return req.data
    }
    editProfile = async (profile: IProfile) => {
        const req = await axiosInstance.patch(`${this.URL}/${this.PATH}/profile`, profile)
        return req.data
    }
    sendCart = async (cart: ICart[]) => {
        // Cast to backend cart type
        let backendCart: Array<IBackendCart> = cart.map((c) => {
            const p: IBackendCart = {
                modifierArticle: c.modifiers?.article || "",
                productId: c.product._id,
                quantity: c.amount,
                type: c.modifiers ? "product" : "bundle"
            }
            return p
        })

        const req = await axiosInstance.patch(`${this.URL}/${this.PATH}/cart`, {
            products: backendCart
        })

        return req.data
    }
    deleteCustomer = async () => {
        const req = await axiosInstance.delete(`${this.URL}/${this.PATH}/`)
        return req.data
    }
    getFavorite = async () => {
        const req = await axiosInstance.get(`${this.URL}/v1/favorite`)
        return req.data
    }
    editFavorite = async (products: string[]) => {
        const req = await axiosInstance.patch(`${this.URL}/v1/favorite`, {
            products
        })
        return req.data
    }
}