import Page from '../Page/Page'
import {Link} from "react-router-dom"
import { useNavigate } from 'react-router-dom'
import { PatternFormat } from 'react-number-format'
import { useCookies } from 'react-cookie'
import classes from './Login.module.css'
import React, { useState, useRef, useEffect } from 'react'
import { DateTime } from 'ts-luxon'
import { LoginService } from '../../api/LoginService'
import { AxiosError } from 'axios'
import { CustomerService } from '../../api/CustomerService'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {Helmet} from "react-helmet-async";

const getErrorMessage = (msg: string) => {
    switch (msg) {
        case 'code mismatch':
            return 'Неверный код'
        case 'phone must be a valid phone number':
            return 'Неверный формат телефона'
        case 'phone or password are mismatch':
            return 'Неверный номер телефона или пароль'
        default:
            return 'Unhandled error'
    }
}

const Login = () => {
    const navigate = useNavigate()
    const loginService = new LoginService()

    const reqCodeBtn = useRef<HTMLButtonElement>(null)
    const verifyBtn = useRef<HTMLButtonElement>(null)

    const [cookies, setCookie] = useCookies(['token'])
    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')


    const {cart} = useTypedSelector(state => state.cartReducer)

    useEffect(() => {
        if (cookies.token) {
            navigate('/account/profile')
        }
    }, [])

    const parsePhone = (phone: string) => phone.replace(/^\+|[\s_()-]/g, '')
    const parseCode = (code: string) => code.replace(/_/g, '')

    const requestCode = async () => {
        try {
            if (reqCodeBtn.current) reqCodeBtn.current.disabled = true
            // TODO: disable button, handle the error
            const codeReq = await loginService.login(parsePhone(phone))
            alert(codeReq.code)
            // TODO: if no error hide reqButton and show codeInput
            if (reqCodeBtn.current) reqCodeBtn.current.classList.add('d-none')

            const codeInput = document.body.querySelector(
                'input[name=one-time-code]'
            ) as HTMLInputElement
            if (codeInput) {
                codeInput.classList.remove('d-none')
                codeInput.focus()
            }

            if (verifyBtn.current) verifyBtn.current.classList.remove('d-none')
        } catch (e) {
            const error = e as AxiosError<{
                message: string | string[]
            }>
            if (error.response)
                alert(
                    getErrorMessage(
                        typeof error.response.data.message === 'string'
                            ? error.response.data.message || ''
                            : error.response.data.message[0]
                    )
                )
        } finally {
            if (reqCodeBtn.current) reqCodeBtn.current.disabled = false
        }
    }

    const verifyPhone = async () => {
        // TODO: disable button, handle error
        try {
            if (verifyBtn.current) verifyBtn.current.disabled = true
            const verifyReq = await loginService.login(
                parsePhone(phone),
                parseCode(code)
            )
            const dt = DateTime.now().plus({ days: 7 })
            setCookie('token', verifyReq.token, {
                path: '/',
                secure: true,
                expires: dt.toJSDate(),
            })

            // SEND NO-AUTH USER CART TO THIS USER
            if(cart.length > 0) {
                const userService = new CustomerService()
                const res = await userService.sendCart(cart)
            }

            navigate('/account/profile')
        } catch (e) {
            const error = e as AxiosError<{
                message: string | string[]
            }>
            if (error.response)
                alert(
                    getErrorMessage(
                        typeof error.response.data.message === 'string'
                            ? error.response.data.message || ''
                            : error.response.data.message[0]
                    )
                )
        } finally {
            if (verifyBtn.current) verifyBtn.current.disabled = false
        }
    }

    return (
        <Page hideMiniCart>
            <Helmet prioritizeSeoTags>
                <title>Вход в личный кабинет</title>
                <link rel="canonical" href="https://parfumeratelier.ru/login" />
            </Helmet>
            <div className={classes.login}>
                <h1>войти</h1>
                <p>Мы отправим на номер SMS-сообщение с кодом подтверждения.</p>
                <PatternFormat
                    name='phone'
                    type='tel'
                    onChange={({ target: { value } }) => setPhone(value)}
                    autoComplete='tel'
                    className='input'
                    inputMode='numeric'
                    format='+7 (###) ###-##-##'
                    allowEmptyFormatting
                    mask='_ '
                />
                <PatternFormat
                    name='one-time-code'
                    type='text'
                    onChange={({ target: { value } }) => setCode(value)}
                    autoComplete='one-time-code'
                    className='input d-none'
                    inputMode='numeric'
                    format='#####'
                    allowEmptyFormatting
                    mask='_ '
                />
                <div className={classes.buttonWrapper}>
                    <button
                        ref={reqCodeBtn}
                        onClick={requestCode}
                        className='btn btn-uppercase'
                        disabled={parsePhone(phone).length !== 11}
                        style={{ marginTop: '1rem' }}>
                        Получить код
                    </button>

                    <button
                        ref={verifyBtn}
                        onClick={verifyPhone}
                        className='btn btn-uppercase d-none'
                        disabled={parseCode(code).length !== 5}
                        style={{ marginTop: '2rem' }}>
                        Подтвердить телефон
                    </button>
                </div>

                <h1 style={{marginTop: "4rem"}}>Нет аккаунта?</h1>
                <p>Можете зарегистрироваться</p>

                <Link to="/signup"
                    className='btn btn-uppercase'
                    style={{ marginTop: '1rem' }}>
                    Присоединиться
                </Link>
            </div>
        </Page>
    )
}

export default Login
