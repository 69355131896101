import { Api } from './Api'
import {axiosInstance} from "./axios"
import {IOrder, IProcessSale} from "../interfaces/Order"

export class OrderService extends Api {
    protected PATH = 'v1/order'

    processSale = async (order: IOrder) => {
        const groups = await axiosInstance.post<IProcessSale>(`${this.URL}/${this.PATH}/create`, {
            ...order
        })
        return groups.data
    }

    create = async (order: IOrder) => {
        const groups = await axiosInstance.post<IProcessSale>(`${this.URL}/${this.PATH}/create`, {
            ...order
        })
        return groups.data
    }

    confirm = async () => {
        const groups = await  axiosInstance.post<string>(`${this.URL}/${this.PATH}/confirm`)
        return groups.data
    }
}