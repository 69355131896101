import Page from '../Page/Page'
import classes from './Catalog.module.css'
import gClasses from '../Page/Page.module.css'
import {Await, Link, Outlet, useLoaderData, useLocation} from 'react-router-dom'
import React, {Suspense, useEffect, useState} from 'react'
import { Sidebar } from '../../components'
import { ITag, ITagGroup } from '../../interfaces/TagGroup'
import { ErrorElement } from '../../components/errorElement/ErrorElement'
import { Loader } from '../../components/loader/Loader'
import { IItem } from '../../interfaces/Item'
import {IBrand} from "../../interfaces/Brand"
import clsx from "clsx";
import {Helmet} from "react-helmet-async";

export type BannerContextType = {
    setBanner: React.Dispatch<React.SetStateAction<string>>
    filters: ITag[]
    filterPrice: number[]
}

type PromiseType = {
    brands: Promise<Array<IBrand>>
    items: Promise<Array<IItem>>
    groups: Promise<ITagGroup>
    dataType: string
}

interface BrandsMapProps {
    obj: Map<string, Array<IBrand>>
}

const defaultBanner = "https://storage.yandexcloud.net/parfum.public/media/Artboard.jpg"

const Catalog = () => {
    const { items, groups, brands } = useLoaderData() as PromiseType
    const [filters, setFilter] = useState<ITag[]>([])
    const [filterPrice, setFilterPrice] = useState<number[]>([-1, -1])
    const [banner, setBanner] = useState<string>(defaultBanner)

    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname)
    }, [location])

    const getHashBrands = (arr: Array<IBrand>): Array<JSX.Element> => {
        const obj = new Map<string, Array<IBrand>>()
        arr.sort((a, b) => {
            if (a.name.toUpperCase() > b.name.toUpperCase())
                return 1
            if (a.name.toUpperCase() < b.name.toUpperCase())
                return -1

            return 0
        })
        for (let a of arr) {
            let c = a.name.charAt(0).toUpperCase()
            if (obj.has(c)) {
                let newArr = obj.get(c)
                if (newArr) {
                    newArr.push(a)
                    obj.set(c, newArr)
                }
            } else {
                obj.set(c, [a])
            }
        }

        let brands: Array<JSX.Element> = []
        for (const [key, value] of obj) {
            const el = (
                <div style={{marginBottom: "3rem"}}>
                    <h2>{key}</h2>
                    {
                        value.map((v, i) => (
                            <p key={`brand_${i}`}>
                                <Link to={`/brands/${v.slug}`} className={classes.brandLink}>{v.name}</Link>
                            </p>
                        ))
                    }
                </div>
            )

            brands.push(el)
        }

        return brands
    }

    return (
        <Page>
            <div
                className={classes.banner}
                style={{ '--background': `url(${banner ? banner : defaultBanner})` } as React.CSSProperties}
            />
            <div className={gClasses.inner}>
                <div className={clsx(classes.catalog, {[classes.catalogBrands]: location.pathname === "/brands" })}>
                    {
                        location.pathname !== "/brands" ? (
                            <Suspense fallback={<Loader/>}>
                                <Await
                                    resolve={groups}
                                    errorElement={
                                        <ErrorElement msg='Не удалось загрузить фильтры'/>
                                    }>
                                    {(groups) => (
                                        <Sidebar
                                            groups={groups}
                                            onFilterChange={setFilter}
                                            onPriceFilterChange={setFilterPrice}
                                        />
                                    )}
                                </Await>
                            </Suspense>
                        ) : (
                            <>
                                <Helmet prioritizeSeoTags>
                                    <title>Бренды парфюмерии</title>
                                    <link rel="canonical" href={`https://parfumeratelier.ru/brands}`} />
                                    <meta name="description" content={`Заказывайте парфюмерию лучших брендов онлайн в интернет-магазине Parfumer Atelier. Широкий каталог духов известных брендов всего мира: европейских, азиатских, американских, российских.`} />
                                </Helmet>
                                <Suspense fallback={<Loader/>}>
                                    <Await
                                        resolve={brands}
                                        errorElement={
                                            <ErrorElement msg='Не удалось загрузить бренды'/>
                                        }>
                                        {(brands: Array<IBrand>) => {
                                            const bs = getHashBrands(brands)
                                            return (
                                                <>
                                                    <div className={classes.title}>
                                                        <h1>Бренды</h1>
                                                        <p>Выбирайте понравившийся бренды и наслаждайтсь каталогам</p>
                                                    </div>
                                                    <div className={classes.brands}>
                                                        {
                                                            bs.map((m, i) => <div key={`brandChar_${i}`}>{m}</div>)
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }}
                                    </Await>
                                </Suspense>
                            </>
                        )
                    }

                    <Outlet context={{ setBanner, filters, filterPrice }} />
                </div>
            </div>
        </Page>
    )
}

export default Catalog
