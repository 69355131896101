import {FC} from "react"
import classes from "./RetailPoint.module.css"
import clsx from "clsx"
import Whatsapp from "../../assets/images/whatsapp.svg"

const enum Direction {
    Left,
    Right
}

interface IPoint {
    _id: string
    address: {
        city: string
        street: string
        house: string
        notice: string
    }
    name: string
    image: string
    direction?: Direction
}

const RetailPoint: FC<IPoint> = ({ _id, address, name, image, direction= Direction.Left }) => {

    return (
        <div className={clsx(classes.retailPoint, {[classes.reverse]: direction === Direction.Right})}>
            <div className={classes.address}>
                <strong>{address.city}</strong>
                <strong>{address.street} {address.house}</strong>
            </div>

            <div className={classes.description}>
                <strong>{name}</strong>
                <i>{address.notice}</i>
                {/*<span>вс-чт, 10:00-22:00</span>*/}
                {/*<span>пт-сб, 10:00-23:00</span>*/}
            </div>

            <div className={classes.support}>
                <strong>служба поддержки:</strong>
                <a href="tel:+74952601003">+7 (495) 260-10-03</a>
                <a href="https://wa.me/message/M57AZ5K4Y4NRE1" className={classes.wa} target="_blank" rel="noopener noreferrer nofollow">
                    <img src={Whatsapp} alt="" />
                    WhatsApp
                </a>
            </div>

            <img className={classes.image} src={image} alt={name} />
        </div>
    )
}

export default RetailPoint