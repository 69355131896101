import { Api } from './Api'
import { axiosInstance } from "./axios"

export class ProductService extends Api {
    protected PATH = 'v1/product'

    getProductsByBrand = async (brandSlug?: string, limit: number = 100, offset: number = 0) => {
        const groups = await axiosInstance.post(`${this.URL}/${this.PATH}/get?brandSlug=${brandSlug}&sortField=priceDefault&sortType=asc&limit=${limit}&offset=${offset}`, {
            body: {}
        })
        return groups.data
    }

    getProductsByCategory = async (categorySlug?: string) => {
        const req = await axiosInstance.post(`${this.URL}/${this.PATH}/get?categorySlug=${categorySlug}&sortField=priceDefault&sortType=asc`, {
            body: {}
        })
        return req.data
    }

    getProductBySlug = async (slug?: string) => {
        const req = await axiosInstance.post(`${this.URL}/${this.PATH}/get?slug=${slug}`, {
            body: {}
        })
        return req.data
    }

    getProductsByBadge = async (badgeSlug?: string) => {
        const req = await axiosInstance.post(`${this.URL}/${this.PATH}/get?badgeSlug=${badgeSlug}`, {
            body: {}
        })
        return req.data
    }
    getProductsById = async (id: string) => {
        const req = await axiosInstance.post(`${this.URL}/${this.PATH}/get?_id=${id}`, {
            body: {}
        })
        return req.data
    }
}